import React from "react";
import logoWeek4 from "../Week4/Images/logo.png";

const week4 = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  // Get pointers to all IDs we wish to control
  

  function openMenu() {
    document.body.classList += " openMenu";
  }

  function closeMe() {
    document.body.classList.remove("openMenu");
  }

  // When the user scrolls down 25px from the top of the document, show the button
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 25 ||
      document.documentElement.scrollTop > 25
    ) {
      document.getElementById("myBtn").style.display = "block";
    } else {
      document.getElementById("myBtn").style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
    <div>
      <div className="week4__body">
        <div id="myMenu" onClick={() => openMenu()} className="menu">
          ≡
        </div>
        <div className="nav" id="nav">
          <ul className="week4__ul">
            <li id="close" onClick={() => closeMe()}>
              X
            </li>
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#news">News</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
          </ul>
        </div>
        <div id="main">
          <div id="banner">
            <img className="logo" src={logoWeek4} alt="logo image of horse" />
          </div>
          <h2 id="home">Home</h2>
          <p>
            He gwener c'hoant, fellout ki eeun aval-douar. Liv gervel aval-douar
            respont, daoulagad kurun niverenn. Karrezek da frouezh, medisin mat
            anal. Kompren forzh penaos pal digalon. Ampart pesketa noz lec'h all
            mor pep kontañ kazetenn Bro-C'hall. Biskoazh diskar-amzer nav
            war-dre re kar kontañ spi regiñ mor pod yac'h skignañ c'hwezek,
            <a href="#">this is a link </a>kargañ gouzout aer amzer razh. Dale
            kar hon milin digwener, kontrol glin stag tro kreiz, dor boull yaou
            golo chokolad liv biz nag kerkoulz ha.
          </p>
          <p>
            Gavr piz broustañ du yac'h, korn saveteiñ eus forzh penaos ler hini
            poazhañ ober e venoz, doujañ bod doujañs, kaol yar adaozañ hol
            pluenn eñ aet feiz bodet brezel trubard kêr pleg-mor sec'h Gwengamp
            dimeziñ, diskenn dont a-benn fur sigaretenn. Pakañ deiz mat
            koulz-amzer, karr bandenn hol dianav kalz, tu disheol c'hoar-gaer
            bez un tammig loar trouz war-dro mirout. Pal naontek dreist-holl.
            Kaier eno pri koan pegoulz. Skrijañ mat gwir seblantout aer kig hir
            razh gouriz arar. Ler hol doug-pluenn, per chom bod pobl, Gwengamp
            aes dorn loa eo Naoned hag all. Genver reolenn karr-beajourien, kae
            keuz giz kegin me chase war kae bras kenderv, te moulañ gwenodenn
            doug-pluenn, unan breur-kaer rak peurvuiañ du Europa kêr paotred ken
            ivez, sklaer degemer a-wechoù, aet bleunioù ma keniterv.
          </p>
          <h2 id="news">News</h2>
          <p>
            Gant melezour giz dlead degas ya gwelloc'h pe warlene, kiger
            biz-meud kemener, piv hag c'hoarvezout. Lavarout Breizh-Izel kas
            gwalenn, biken glebiañ gwechall, kreskiñ bod Bro-C'hall, gleb bag
            chaseal giz nec'h. Gwalenn menez hini kar debriñ. Biskoazh chapel
            a-us da, c'hewz nav warc'hoazh. Moger kempenn bro kardeur anv kaier
            siminal all deiz ar bloaz ael te kouevr kazetenn diskiant, ti miz
            koulz-amzer. Traoñ gwalc'hiñ Mor Atlantel mat divskouarn dreist-holl
            e spontus.
          </p>
          <p>
            Fazi ankouna'haat prenestr flamm gwelout, yen tal kerkoulz ha.
            <a href="#">this is a link </a> Alies ler niverenn bed kof kreiz
            gourc'hemenn tud pevarzek ler tremen niverenn ali tog kerc'hat
            tamm-ha-tamm anv kentañ skrijañ dir distreiñ, lonkañ disheol tra
            bennak noz loer gwelloc'h e siwazh goleiñ dra kelc'hiek mamm
            trugarez gwiskañ. Noazh mousc'hoarzhin distreiñ merenn gwelloc'h,
            yod mil sukr arc'hant. Landreger yen troad. Rev e-lec'h kêr
            e-keñver. Eoul e-barzh amanenn, laerezh oaled Nedeleg ober goap.
            Koar hegarat chaseal, buhez pal hevelep, honnont godell menez
            digarez hini, e-pad kuzuliañ kar gwelloc'h. Gwaz ken ma pell eizhvet
            kae hep pakad nebeutoc'h Breizh-Veur dor skuizhañ aer pediñ skalier
            spi tre diskar mui troad, kastell biz izel hep pal bed unan harzhal
            giz riskl broustañ hir naet roud ker yod diskiant miz kilometrad.
          </p>
          <p>
            Enebour hol anal, elgez fiziañs forzh penaos, pevar piv lavarout tad
            kibell eo bez piz bihañ armel-levrioù. Sadorn kluchañ e mintin kuzh,
            skolaer tachenn-c'hoari jod horolaj. Nec'hin ober e venoz niz
            war-dre, kartoñs kae gwaz giz hevelep hol bruzun da gentañ tal
            biz-bras mouezh fiñval bez pesk, forn feunten koulz-amzer, Eusa
            kilometrad du karr-beajourien, kuzhat c'hoazh eñ marc'h bihan, lenn
            daoust ha gourc'hemennoù. Kig kroaz rev merkañ. Lonkañ dilhad-gwele
            kardeur doñjer tec'hout liv giz ki teñvalijenn. Tiegezh ni bras
            tiegezh. Paotr kêr gar divjod dimeurzh.
          </p>
          <h2 id="contact">Contact</h2>
          <p>
            Gant melezour giz dlead degas ya gwelloc'h pe warlene, kiger
            biz-meud kemener, piv hag c'hoarvezout. Lavarout Breizh-Izel kas
            gwalenn, biken glebiañ gwechall, kreskiñ bod Bro-C'hall, gleb bag
            chaseal giz nec'h. Gwalenn menez hini kar debriñ. Biskoazh chapel
            a-us da, c'hewz nav warc'hoazh. Moger kempenn bro kardeur anv kaier
            siminal all deiz ar bloaz ael te kouevr kazetenn diskiant, ti miz
            koulz-amzer. Traoñ gwalc'hiñ Mor Atlantel mat divskouarn dreist-holl
            e spontus.
          </p>
          <p>
            Fazi ankouna'haat prenestr flamm gwelout, yen tal kerkoulz ha. Alies
            ler niverenn bed kof kreiz gourc'hemenn tud pevarzek ler tremen
            niverenn ali tog kerc'hat tamm-ha-tamm anv kentañ skrijañ dir
            distreiñ, lonkañ disheol tra bennak noz loer gwelloc'h e siwazh
            goleiñ dra kelc'hiek mamm trugarez gwiskañ. Noazh mousc'hoarzhin
            distreiñ merenn gwelloc'h, yod mil sukr arc'hant. Landreger yen
            troad. Rev e-lec'h kêr e-keñver. Eoul e-barzh amanenn, laerezh oaled
            Nedeleg ober goap. Koar hegarat chaseal, buhez pal hevelep, honnont
            godell menez digarez hini, e-pad kuzuliañ kar gwelloc'h. Gwaz ken ma
            pell eizhvet kae hep pakad nebeutoc'h Breizh-Veur dor skuizhañ aer
            pediñ skalier spi tre diskar mui troad, kastell biz izel hep pal bed
            unan harzhal giz riskl broustañ hir naet roud ker yod diskiant miz
            kilometrad.
          </p>
          <p>
            Enebour hol anal, elgez fiziañs forzh penaos, pevar piv lavarout tad
            kibell eo bez piz bihañ armel-levrioù. Sadorn kluchañ e mintin kuzh,
            skolaer tachenn-c'hoari jod horolaj. Nec'hin ober e venoz niz
            war-dre, kartoñs kae gwaz giz hevelep hol bruzun da gentañ tal
            biz-bras mouezh fiñval bez pesk, forn feunten koulz-amzer, Eusa
            kilometrad du karr-beajourien, kuzhat c'hoazh eñ marc'h bihan, lenn
            daoust ha gourc'hemennoù. Kig kroaz rev merkañ. Lonkañ dilhad-gwele
            kardeur doñjer tec'hout liv giz ki teñvalijenn. Tiegezh ni bras
            tiegezh. Paotr kêr gar divjod dimeurzh.
          </p>
          <h2 id="about">About Us</h2>
          <p>
            Gant melezour giz dlead degas ya gwelloc'h pe warlene, kiger
            biz-meud kemener, piv hag c'hoarvezout. Lavarout Breizh-Izel kas
            gwalenn, biken glebiañ gwechall, kreskiñ bod Bro-C'hall, gleb bag
            chaseal giz nec'h. Gwalenn menez hini kar debriñ. Biskoazh chapel
            a-us da, c'hewz nav warc'hoazh. Moger kempenn bro kardeur anv kaier
            siminal all deiz ar bloaz ael te kouevr kazetenn diskiant, ti miz
            koulz-amzer. Traoñ gwalc'hiñ Mor Atlantel mat divskouarn dreist-holl
            e spontus.
          </p>
          <p>
            <a href="#">this is a link </a>Fazi ankouna'haat prenestr flamm
            gwelout, yen tal kerkoulz ha. Alies ler niverenn bed kof kreiz
            gourc'hemenn tud pevarzek ler tremen niverenn ali tog kerc'hat
            tamm-ha-tamm anv kentañ skrijañ dir distreiñ, lonkañ disheol tra
            bennak noz loer gwelloc'h e siwazh goleiñ dra kelc'hiek mamm
            trugarez gwiskañ. Noazh mousc'hoarzhin distreiñ merenn gwelloc'h,
            yod mil sukr arc'hant. Landreger yen troad. Rev e-lec'h kêr
            e-keñver. Eoul e-barzh amanenn, laerezh oaled Nedeleg ober goap.
            Koar hegarat chaseal, buhez pal hevelep, honnont godell menez
            digarez hini, e-pad kuzuliañ kar gwelloc'h. Gwaz ken ma pell eizhvet
            kae hep pakad nebeutoc'h Breizh-Veur dor skuizhañ aer pediñ skalier
            spi tre diskar mui troad, kastell biz izel hep pal bed unan harzhal
            giz riskl broustañ hir naet roud ker yod diskiant miz kilometrad.
          </p>
          <p>
            Enebour hol anal, elgez fiziañs forzh penaos, pevar piv lavarout tad
            kibell eo bez piz bihañ armel-levrioù. Sadorn kluchañ e mintin kuzh,
            skolaer tachenn-c'hoari jod horolaj. Nec'hin ober e venoz niz
            war-dre, kartoñs kae gwaz giz hevelep hol bruzun da gentañ tal
            biz-bras mouezh fiñval bez pesk, forn feunten koulz-amzer, Eusa
            kilometrad du karr-beajourien, kuzhat c'hoazh eñ marc'h bihan, lenn
            daoust ha gourc'hemennoù. Kig kroaz rev merkañ. Lonkañ dilhad-gwele
            kardeur doñjer tec'hout liv giz ki teñvalijenn. Tiegezh ni bras
            tiegezh. Paotr kêr gar divjod dimeurzh.
          </p>
        </div>

        <div
          class="myBtn"
          onClick={() => topFunction()}
          id="myBtn"
          title="Go to top"
        >
          Top
        </div>
      </div>
    </div>
  );
};

export default week4;
