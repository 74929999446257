import a1 from "../Week1/Images/a1.png";
import a2 from "../Week1/Images/a2.png";
import a3 from "../Week1/Images/a3.png";
import React from "react";

const week1 = () => {
  return (
    <div className="week1__background">
      <h1>Animation &amp; Transition Demonstrations <br /> Week 1</h1>
      <div className="wee1__container">
        <div className="week1__animations">
          <div>
            <h2>CSS Animation 1</h2>
            <img className="week1__img" id="a1" src={a1} alt="animated image" />
          </div>
          <div>
            <h2>CSS Animation 2</h2>
            <img className="week1__img" id="a2" src={a2} alt="animated image" />
          </div>
          <div>
            <h2>CSS Animation 3</h2>
            <img className="week1__img" id="a3" src={a3} alt="animated image" />
          </div>
        </div>
        <div className="week1__separator"></div>
        <div className="week1__transistions">
          <div>
            <h2>CSS Transistion 1</h2>
            <img className="week1__img" id="t1" src={a1} alt="transistioned image" />
          </div>
          <div>
            <h2>CSS Transistion 2</h2>
            <img className="week1__img" id="t2" src={a2} alt="transistioned image" />
          </div>
          <div>
            <h2>CSS Transistion 3</h2>
            <img className="week1__img" id="t3" src={a3} alt="transitioned image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default week1;
