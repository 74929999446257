import "./App.css";
import Home from "./Home";
import Footer from "./footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Week1 from "./WeeklyLabs/Week1/Week1";
import Week2 from "./WeeklyLabs/Week2/Week2";
import Week3 from "./WeeklyLabs/Week3/Week3";
import Week4 from "./WeeklyLabs/Week4/Week4";
import Week5 from "./WeeklyLabs/Week5/Week5";
import Week6 from "./WeeklyLabs/Week6/Week6";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/week1" element={<Week1 />} />
          <Route path="/week2" element={<Week2 />} />
          <Route path="/week3" element={<Week3 />} />
          <Route path="/week4" element={<Week4 />} />
          <Route path="/week5" element={<Week5 />} />
          <Route path="/week6" element={<Week6 />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
