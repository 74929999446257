import { useEffect } from "react";
import * as THREE from "three";

const Landing = () => {
    useEffect(() => {
        const canvas = document.querySelector("canvas.webgl");
    
        const scene = new THREE.Scene();
    
        let mouseX = 0,
          mouseY = 0,
          windowHalfX = window.innerWidth / 2,
          windowHalfY = window.innerHeight / 2;
        const spheres = [];
    
        document.addEventListener("mousemove", onDocumentMouseMove);
    
        const geometry = new THREE.TetrahedronGeometry(3);
        const material = new THREE.MeshPhongMaterial({
          specular: 0x333333,
          shininess: 100,
          combine: THREE.MixOperation,
          reflectivity: 0.4,
        });
    
        var light1 = new THREE.DirectionalLight(0xff0000, 1.5);
        light1.position.set(100, 0, 0);
        scene.add(light1);
    
        var light2 = new THREE.DirectionalLight(0xff0000, 1.5);
        light2.position.set(-100, 0, 0);
        scene.add(light2);
    
        const meshT = {
          triangles: 75,
        };
    
        for (let i = 0; i < meshT.triangles; i++) {
          const meshScale = Math.random() * 0.35 * 0.7;
          const mesh = new THREE.Mesh(geometry, material);
    
          mesh.position.x = Math.random();
          mesh.position.y = Math.random();
          mesh.position.z = Math.random() * 50;
          mesh.scale.set(meshScale, meshScale, meshScale);
    
          scene.add(mesh);
    
          spheres.push(mesh);
        }
    
        const sizes = {
          width: window.innerWidth,
          height: window.innerHeight,
        };
    
        window.addEventListener("resize", () => {
          windowHalfX = window.innerWidth / 2;
          windowHalfY = window.innerHeight / 2;
    
          sizes.width = window.innerWidth;
          sizes.height = window.innerHeight;
    
          camera.aspect = sizes.width / sizes.height;
          camera.updateProjectionMatrix();
    
          renderer.setSize(sizes.width, sizes.height);
          renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        });
    
        const camera = new THREE.PerspectiveCamera(
          60,
          sizes.width / sizes.height,
          0.1,
          100
        );
        camera.position.z = 25;
        scene.add(camera);
    
        function onDocumentMouseMove(event) {
          mouseX = (event.clientX - windowHalfX) / 100;
          mouseY = (event.clientY - windowHalfY) / 100;
        }
    
        const renderer = new THREE.WebGLRenderer({
          canvas: canvas,
          antialias: true,
          alpha: true,
        });
    
        renderer.setSize(sizes.width, sizes.height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    
        const clock = new THREE.Clock();
    
        function tick() {
          const timer = 0.0001 * Date.now();
    
          camera.position.x += (mouseX - camera.position.x) * 0.1;
          camera.position.y += (-mouseY - camera.position.y) * 0.1;
    
          camera.lookAt(scene.position);
    
          for (let i = 0, il = spheres.length; i < il; i++) {
            const sphere = spheres[i];
    
            sphere.position.x = 10 * Math.cos(timer + i);
            sphere.position.y = 5 * Math.sin(timer + i * 1.1);
    
            sphere.rotation.x = 5 * Math.cos(timer + i);
            sphere.rotation.y = 5 * Math.sin(timer + i * 1.1);
          }
    
          renderer.render(scene, camera);
    
          window.requestAnimationFrame(tick);
    
          // console.log(timer)
        }
    
        tick();
    });
  
  return <div>
      <>
      <canvas class="webgl"></canvas>

      <section id="summary">
        <div class="summary-position">
          <h1 data-aos="fade-up" data-aos-delay="200">
            Hi I'm <span class="red-color">Joshua.</span>
          </h1>
          <div data-aos="fade-up" data-aos-delay="400" class="summary-text">
            I'm an aspiring{" "}
            <span class="red-color">Frontend Web Developer and Game Developer </span>
            passionate about building web applications and developing video games with great user
            experiences.
          </div>

          <div class="svg-wrapper" data-aos="fade-up" data-aos-delay="600">
            <div>
              <a href="https://www.linkedin.com/in/joshua-khoury/">
                <svg
                  class="MuiSvgIcon-root"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"></path>
                </svg>
              </a>
            </div>
            <div>
              <a href="mailto:khourydev@gmail.com">
                <svg
                  class="MuiSvgIcon-root"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>

        <a class="scroll-downs" href="#about">
          <div data-aos="fade-down" data-aos-delay="800">
            <div class="mousey">
              <div class="scroller"></div>
            </div>
          </div>
        </a>
      </section>
    </>
  </div>;
};

export default Landing;
