import React from "react";
import { FiLink } from "react-icons/fi";
import { Link } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";

import week1img from "../WeeklyLabs/Images/Week1.png";
import week2img from "../WeeklyLabs/Images/Week2.png";
import week3img from "../WeeklyLabs/Images/Week3.png";
import week4img from "../WeeklyLabs/Images/Week4.png";
import week5img from "../WeeklyLabs/Images/Week5.png";
import week6img from "../WeeklyLabs/Images/Week6.png";

const ProjectDisplay = () => {
  AOS.init({ duration: 1500, once: true });
  return (
    <section id="projects">
      <div className="projects__section--wrapper">
        <h2
          className="projects__section--title"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          Here Are My Weekly Projects.
        </h2>
        <ul className="project__list">
          <li className="project" data-aos="fade-up" data-aos-delay="300">
            <div className="project__title">Week 1</div>
            <div className="project__wrapper">
              <img src={week1img} className="project__img" alt="" />
              <div className="project__wrapper--bg"></div>
              <div className="project__description">
                <h3 className="project__description--title">Week 1</h3>
                <h4 className="project__description--sub-title">
                  Html, CSS, React, Firebase
                </h4>
                <p className="project__description--para">Animating circles</p>
                <div className="project__description--links">
                  <Link
                    to="/week1"
                    className="project__description--link"
                    target={`_blank`}
                  >
                    <FiLink />
                  </Link>
                </div>
              </div>
            </div>
          </li>

          <li className="project" data-aos="fade-up" data-aos-delay="500">
            <div className="project__title">Week 2</div>
            <div className="project__wrapper">
              <img src={week2img} className="project__img" alt="" />
              <div className="project__wrapper--bg"></div>
              <div className="project__description">
                <h3 className="project__description--title">Week 2</h3>
                <h4 className="project__description--sub-title">
                  Html, CSS, React, Firebase
                </h4>
                <p className="project__description--para">
                  Animating squares and Bouncing ball
                </p>
                <div className="project__description--links">
                  <Link
                    to="/week2"
                    className="project__description--link"
                    target={`_blank`}
                  >
                    <FiLink />
                  </Link>
                </div>
              </div>
            </div>
          </li>

          <li className="project" data-aos="fade-up" data-aos-delay="700">
            <div className="project__title">Week 3</div>
            <div className="project__wrapper">
              <img src={week3img} className="project__img" alt="" />
              <div className="project__wrapper--bg"></div>
              <div className="project__description">
                <h3 className="project__description--title">Week 3</h3>
                <h4 className="project__description--sub-title">
                  Html, CSS, JavaScript, React, Firebase
                </h4>
                <p className="project__description--para">Modals / Pop-up</p>
                <div className="project__description--links">
                  <Link
                    to="/week3"
                    className="project__description--link"
                    target={`_blank`}
                  >
                    <FiLink />
                  </Link>
                </div>
              </div>
            </div>
          </li>

          <li className="project" data-aos="fade-up" data-aos-delay="900">
            <div className="project__title">Week 4</div>
            <div className="project__wrapper">
              <img src={week4img} className="project__img" alt="" />
              <div className="project__wrapper--bg"></div>
              <div className="project__description">
                <h3 className="project__description--title">Week 4</h3>
                <h4 className="project__description--sub-title">
                  Html, CSS, JavaScript, React, Firebase
                </h4>
                <p className="project__description--para">UI Animations</p>
                <div className="project__description--links">
                  <Link
                    to="/week4"
                    className="project__description--link"
                    target={`_blank`}
                  >
                    <FiLink />
                  </Link>
                </div>
              </div>
            </div>
          </li>

          <li className="project" data-aos="fade-up" data-aos-delay="1100">
            <div className="project__title">Week 5</div>
            <div className="project__wrapper">
              <img src={week5img} className="project__img" alt="" />
              <div className="project__wrapper--bg"></div>
              <div className="project__description">
                <h3 className="project__description--title">Week 5</h3>
                <h4 className="project__description--sub-title">
                  Html, CSS, JavaScript, React, Firebase
                </h4>
                <p className="project__description--para">
                  Introduction to Canvas
                </p>
                <div className="project__description--links">
                  <Link
                    to="/week5"
                    className="project__description--link"
                    target={`_blank`}
                  >
                    <FiLink />
                  </Link>
                </div>
              </div>
            </div>
          </li>

          <li className="project" data-aos="fade-up" data-aos-delay="1300">
            <div className="project__title">Week 6</div>
            <div className="project__wrapper">
              <img src={week6img} className="project__img" alt="" />
              <div className="project__wrapper--bg"></div>
              <div className="project__description">
                <h3 className="project__description--title">Week 6</h3>
                <h4 className="project__description--sub-title">
                  Html, CSS, JavaScript, React, Firebase
                </h4>
                <p className="project__description--para">
                  Game development with Canvas
                </p>
                <div className="project__description--links">
                  <Link
                    to="/week6"
                    className="project__description--link"
                    target={`_blank`}
                  >
                    <FiLink />
                  </Link>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default ProjectDisplay;
