import React from "react";
import horseimg from "../Week3/Images/w_Wild-Horses-1.png";


const week3 = () => {

  window.onload = function () {
    console.log("document.onload", Date.now());

    var myBtn = document.querySelector("#myBtn");
    myBtn.addEventListener("click", openForm, false);

    var close = document.querySelector("#close");
    close.addEventListener("click", closeMe, false);

    var myForm = document.querySelector("#myForm");

    function openForm() {
      //Unhide form
      myForm.style.display = "block";
    }

    function closeMe() {
      //Hide form
      myForm.style.display = "none";
    }
  }

  return (
    <div className="week3__wrapper">
      <h1>Example Popup Form</h1>
      <button id="myBtn" class="w3-btn w3-round-large w3-blue">
        Click me
      </button>
      <br />
      <br />
      <div id="main">
        <p>
          <img className="week3__img" class="w3-image w3-card" src={horseimg} alt="week3" /> Niz Krouer
          gwir ret perc'henn jod koulz ha mil gwalc'hiñ ali tri-ugent
          c'hoarvezout eus fraoñval mar ti beuziñ nec'hin hent-houarn, aes
          munutenn mae mantell, aon arabat digempenn hag pennad aes santout niz
          kustum, dre nevez bruched teñvalijenn. Lunedoù piz ober eo mat pegeit
          don moereb.
        </p>
        <p>
          Kêr las poull-kalon kig ar gwellañ Kembre poan grizilh froud. Bleunioù
          start yar derc'hel Breizh-Izel aod martolod goude tad-kozh prizius.
        </p>
        <p>
          Pal koulskoude he ar gwellañ. Kaoued tachenn eta Gwengamp. Gar piler
          gouzañv kerkent, evel-se teñvalijenn ma jiletenn, kelenner he park
          sal-debriñ bev kar golo ti-hent-houarn eur skouarn noz kloc'h,
          pesketaer anal touellañ bandenn, linenn digarez yod teñvalijenn rak
          fiñval liv er-maez eil pennad.
        </p>
      </div>

      <div
        id="myForm"
        class="w3-card w3-padding w3-border w3-round-xlarge w3-yellow"
      >
        <h2 className="week3__h2">Input Form</h2>
        <div id="close" class="w3-btn w3-right w3-round-large w3-red">
          X
        </div>
        <form action="#" method="post" />
        <input class="w3-input" type="text" />
        <label>First Name</label>
        <input class="w3-input" type="text" />
        <label>Last Name</label>
      </div>
    </div>
  );
};
export default week3;
