import React from 'react'

const footer = () => {
    return (
        <div>
            <footer className="project__footer">Copyright 2022</footer>
        </div>
    )
}

export default footer
