import React from "react";
import Landing from "./WeeklyLabs/Landing";
import ProjectDisplay from "./WeeklyLabs/ProjectDisplay";

const Home = () => {
  return (
    <div>
      <Landing />
      <ProjectDisplay />
    </div>
  );
};

export default Home;
