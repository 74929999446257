import React from "react";

const week2 = () => {
  return (
    <div className="week2__background">
      <h1>Timing Functions <br/>Week 2</h1>
      <div className="week2__container">
        <h2>Hover Effects</h2>
        <div className="week2__transitions">
          <div id="btn1"></div>
          <div id="btn2"></div>
          <div id="btn3"></div>
          <div id="btn4"></div>
        </div>

        <div className="week2__separator"></div>

        <h2>Movement Effects</h2>
        <div className="week2__animations">
          <div id="ball"></div>
        </div>
      </div>
    </div>
  );
};

export default week2;
