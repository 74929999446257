import React, { useEffect, useRef } from "react";
import horse from "../Week5/Images/horse.png";

const Week5 = () => {
  useEffect(() => {
    var canvas = document.querySelector("#canvas");
    var ctx = canvas.getContext("2d");

    //Rectangle with gradient
    ctx.beginPath();

    // Create gradient
    var grd = ctx.createLinearGradient(0, 0, 1000, 300);
    grd.addColorStop(0, "#ffffff");
    grd.addColorStop(1, "#a3c2c2");

    // Fill with gradient
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, 1000, 300);

    var img = new Image(400, 320);
    img.src = horse;
    img.onload = function () {
      ctx.beginPath();
      ctx.drawImage(img, 0, -10);
      ctx.drawImage(img, 350, 110, 200, 175);
      ctx.drawImage(img, 500, 130, 200, 175);

      ctx.font = "italic 48px arial";
      ctx.fillStyle = "rgba(50,50,50, 0.5)";
      ctx.fillText("The Steeds of Rivendell", 203, 152);
      ctx.fillStyle = "#669966";
      ctx.fillText("The Steeds of Rivendell", 200, 150);

      ctx.save();
      ctx.translate(920, 70);
      ctx.rotate(Math.PI / 2);
      ctx.font = "bold 38px arial";
      ctx.textAlign = "left";
      ctx.fillStyle = "rgba(60,60,60, 0.8)";
      ctx.fillText("Ride On!", -2, -2);
      ctx.fillStyle = "#ffffff";
      ctx.fillText("Ride On!", 0, 0);
      ctx.restore();

      var startX = 700;
      var startY = 45;

      ctx.strokeStyle = "#ffffff";

      ctx.beginPath();
      ctx.lineWidth = 3;
      ctx.moveTo(startX, startY);
      ctx.lineTo(startX - 50, startY + 30);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(startX, startY);
      ctx.lineWidth = 5;
      ctx.lineTo(startX + 15, startY + 15);
      ctx.stroke();

      ctx.beginPath();
      ctx.lineWidth = 3;
      ctx.moveTo(startX + 50, startY);
      ctx.lineTo(startX, startY + 30);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(startX + 50, startY);
      ctx.lineWidth = 5;
      ctx.lineTo(startX + 65, startY + 15);
      ctx.stroke();
    };
  }, []);

  return (
    <div>
      <canvas
        className="week5__canvas"
        id="canvas"
        width="1000px"
        height="300px"
      ></canvas>
    </div>
  );
};

export default Week5;
